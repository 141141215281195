import forms from '/styles/Forms.module.scss';
import newsletter from '/styles/Newsletter.module.scss';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import CtaButton from 'components/ui/ctaButton';
import { ISubscriber } from 'data/abstractions/configuratorInterfaces';
import { isEmailValid } from 'helpers/validators';
import { apiPost } from 'helpers/apiHelpers';

export const subscriberExists = async (data: any) => {
	return apiPost('/api/subscriber/exists', data);
};

export const createSubscriber = async (data: ISubscriber) => {
	return apiPost('/api/subscriber/create', data);
};

export default function Newsletter() {
	const intl = useIntl();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const subscribe = async (
		e:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		e.preventDefault();

		const isValid = isEmailValid(email);
		if (!isValid) {
			setErrorMessage('invalid_email');
			setShowError(true);
			return;
		}

		const exists = await subscriberExists({ email: email });
		if (exists) {
			setErrorMessage('subscriber_exists');
			setShowError(true);
		} else {
			const subscriber = await createSubscriber({ name: name, email: email });
			setName('');
			setEmail('');
		}
	};

	useEffect(() => {
		setShowError(false);
		setErrorMessage('');
	}, [name, email]);

	return (
		<section className={newsletter.newsletter} id='newsletter'>
			<div className='container'>
				<div className='pt-5 pb-5'>
					<div className='row justify-content-between align-items-center'>
						<div className='col-12 col-lg-4'>
							<div>
								<h3 className={``}>
									<FormattedMessage
										id='component.newsletter.title'
										defaultMessage='Subscribe to the Newsletter'
									/>
								</h3>
							</div>
							<div>
								<p className='mb-0'>
									<FormattedMessage
										id='component.newsletter.content'
										defaultMessage='Do you want to stay up to date with the latest information? Subscribe to the newsletter.'
									/>
								</p>
							</div>
						</div>
						<div className='col-12 col-lg-4 align-items-center d-flex'>
							<div className={`${forms.form} w-100`}>
								<div>
									<input
										type='text'
										required
										className='form-control'
										id='firstName'
										placeholder={intl.formatMessage({
											id: 'component.contact.last_and_first_name',
											defaultMessage: 'Last and first name',
										})}
										value={name}
										onChange={(e) => setName(e.target.value)}
										autoComplete='name'
									></input>
								</div>
								<div>
									<input
										type='email'
										required
										className='form-control'
										id='email'
										placeholder={intl.formatMessage({
											id: 'component.contact.email',
											defaultMessage: 'Email',
										})}
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										autoComplete='email'
									></input>
									<div
										className={`invalid-feedback ${showError ? 'd-block' : ''}`}
									>
										<FormattedMessage
											id={`component.errors.${errorMessage}`}
											defaultMessage='Unknown error.'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='col-12 col-lg-4 align-items-start d-flex mt-3 mt-lg-0 w-auto'>
							<CtaButton
								label={intl.formatMessage({
									id: 'component.newsletter.subscribe',
									defaultMessage: 'Subscribe',
								})}
								handler={subscribe}
							></CtaButton>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
