import homes from '/styles/pages/Home.module.scss';
import Nav from 'components/navigation/navigation';
import Hero from 'components/landing/homepage/sections/hero';
import HowItWorks from 'components/landing/homepage/sections/howItWorks';
import Benefits from 'components/landing/homepage/sections/benefits';
import Platform from 'components/landing/homepage/sections/platform';
import Estimator from 'components/landing/homepage/sections/estimator';
import Testimonials from 'components/landing/homepage/sections/testimonials';
import Newsletter from 'components/landing/homepage/sections/newsletter';
import FeaturedIn from 'components/landing/homepage/sections/featuredIn';
import SocialProof from 'components/landing/homepage/sections/socialProof';
import Footer from 'components/footer/footer';

export default function Home() {
	return (
		<div className={homes.container}>
			<Nav></Nav>
			<main className={homes.main}>
				<Hero></Hero>
				<Estimator></Estimator>
				<HowItWorks></HowItWorks>
				<SocialProof></SocialProof>
				<Benefits></Benefits>
				<FeaturedIn></FeaturedIn>
				<Testimonials></Testimonials>
				<Platform></Platform>
				<Newsletter></Newsletter>
			</main>
			<Footer></Footer>
		</div>
	);
}
