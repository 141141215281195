import React from 'react';
import howItWorks from '/styles/HowItWorks.module.scss';
import cards from '/styles/ui/CardComponents.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import Image from 'next/image';

export default function HowItWorks() {
	const intl = useIntl();
	return (
		<section className={howItWorks.howItWorks} id='howItWorks'>
			<div className='container'>
				<div className='pt-5 pb-5'>
					<h2
						className={`${howItWorks.heading} h1 col-lg-5 col-12 d-flex align-items-center`}
					>
						<FormattedMessage
							id='component.how_it_works.title'
							defaultMessage='How it works?'
						/>
					</h2>
					<div className='row mt-4'>
						{Array.from(Array(4).keys()).map((index) => {
							return (
								<div key={index} className='col-12 col-lg-6 mt-3'>
									<div
										className={`card h-100 p-4 ${cards.transparentCard} bg-white`}
									>
										<div className={`${cards.counter} fw-bold`}>
											{index + 1}
										</div>
										<div className='row'>
											<div className='col-4'>
												<Image
													src={`/icons/how-it-works/how-it-works-${
														index + 1
													}.svg`}
													width={100}
													height={100}
													alt='icon'
												></Image>
											</div>
											<div className='align-items-center col-8 d-flex'>
												<FormattedMessage
													id={`component.how_it_works.content_${index + 1}`}
													defaultMessage='How it works?'
												/>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</section>
	);
}
