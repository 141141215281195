import React, { useState, useRef, useEffect } from 'react';
import hero from '/styles/Hero.module.scss';
import PhotonAnimation from 'components/animation/photonAnimation';
import { useIntl } from 'react-intl';
import Carousel from 'components/animation/carousel';
import CtaButton from 'components/ui/ctaButton';
import {
	CAROUSEL_IMGS,
	CAROUSEL_OPTIONS,
	CAROUSEL_BTNS_TEXTS,
} from 'helpers/slidesConfigurations';
import CarouselSlider from 'components/animation/carouselSlider';

export default function Hero() {
	const intl = useIntl();
	const [selectedHeroIndex, setHeroIndex] = useState(0);
	const containerRef = useRef(null);
	const [angle, setAngle] = useState(0);
	const [photonVisible, setPhotonVisible] = useState(false);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			function updateAngle() {
				const originalHeight =
					(containerRef?.current as HTMLElement | null)?.clientHeight ?? 1;
				const originalWidth =
					(containerRef.current as HTMLElement | null)?.clientWidth ?? 1;
				let height = originalHeight;
				let width = originalWidth / 2;
				if (originalWidth < 992) {
					//scrollbar width
					height = (originalHeight * 2) / 3;
					width = originalWidth;
				} else {
					height = originalHeight / 2;
					width = originalWidth / 3;
				}

				const angleRad = Math.atan(height / width);
				const angle = (angleRad * 180) / Math.PI;
				setAngle(angle);
				setPhotonVisible(true);
			}
			window.addEventListener('resize', updateAngle, { passive: true });
			updateAngle();

			return () => window.removeEventListener('resize', updateAngle);
		}
	}, []);

	const childToParent = (selectedIndex: number) => {
		setHeroIndex(selectedIndex);
	};

	return (
		<>
			<section className={hero.hero}>
				<div className={hero.heroContainer} ref={containerRef}>
					<Carousel
						slides={CAROUSEL_IMGS}
						options={CAROUSEL_OPTIONS}
						selectedSlide={selectedHeroIndex}
						priority={false}
					></Carousel>
					<div className={hero.meshContainer}>
						<div className={hero.mesh}></div>
					</div>
					<div className={hero.photonContainer}>
						{photonVisible && (
							<PhotonAnimation
								icon={''}
								isSlider={false}
								selectedIndex={selectedHeroIndex}
								selectIndex={childToParent}
								angle={angle}
							></PhotonAnimation>
						)}
					</div>
				</div>
				<div className={hero.heroContent}>
					<div className={`${hero.heroContentContainer} container`}>
						<CtaButton
							heading={intl.formatMessage({
								id: CAROUSEL_BTNS_TEXTS[selectedHeroIndex].heading.id,
								defaultMessage:
									CAROUSEL_BTNS_TEXTS[selectedHeroIndex].heading.defaultMessage,
							})}
							label={intl.formatMessage({
								id: CAROUSEL_BTNS_TEXTS[selectedHeroIndex].label.id,
								defaultMessage:
									CAROUSEL_BTNS_TEXTS[selectedHeroIndex].label.defaultMessage,
							})}
							href={CAROUSEL_BTNS_TEXTS[selectedHeroIndex].targetSection}
							handler={(e) => {
								const targetSection = document.querySelector(
									CAROUSEL_BTNS_TEXTS[selectedHeroIndex].targetSection
								);
								if (targetSection !== null) {
									e.preventDefault();
									const offsetTop =
										(targetSection as HTMLElement)?.offsetTop ?? 0;
									scroll({
										top: offsetTop,
										behavior: 'smooth',
									});
								}
							}}
						></CtaButton>
					</div>
				</div>
			</section>
			<CarouselSlider
				icon={''}
				isSlider={true}
				selectedIndex={selectedHeroIndex}
				selectIndex={childToParent}
				angle={angle}
			></CarouselSlider>
		</>
	);
}
