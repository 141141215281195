import { IPaginationData } from 'components/ui/accordions/IPagination';
import logger from 'helpers/logging';
import { PROJECT_TYPE } from './statusHelpers';
import { IApiFilter, IFilter } from 'components/ui/filters/IFilter';
import { ENV_VARS } from './constants';
import { SCREENSHOT_TYPE } from 'data/models/types/screenshotType';

export const apiPost = async <T>(url: string, data: T) => {
	try {
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});

		if (!response.ok) {
			logger.error(`Error ${response.status}: ${response.statusText}`);
		}

		const content = await response.json();
		return content;
	} catch (error) {
		logger.error(`Error in apiPost: ${error}`);
		throw error;
	}
};

export const apiGet = async <T>(url: string, query: T) => {
	try {
		const response = await fetch(url + query, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});

		if (!response.ok) {
			logger.error(`Error ${response.status}: ${response.statusText}`);
		}

		const content = await response.json();
		return content;
	} catch (error) {
		logger.error(`Error in apiPost: ${error}`);
		throw error;
	}
};

export interface IQueryParam {
	name: string;
	value?: string | number | boolean;
}

export const addQueryParamToUrl = (url: string, queryParam?: IQueryParam) => {
	let fullUrl = url;
	if (queryParam !== undefined && queryParam.value !== undefined) {
		const queryValue = encodeURIComponent(String(queryParam.value));
		fullUrl += `${!url.includes('?') ? '?' : '&'}${
			queryParam.name
		}=${queryValue}`;
	}
	return fullUrl;
};

export const dbFetch = async <T>(
	route: string,
	apiType: string,
	requestType?: number,
	page?: number,
	limit?: number,
	search?: string,
	orderBy?: string,
	orderDir?: string,
	username?: string,
	filters?: IApiFilter[]
) => {
	let url = `/api/${route}/${apiType}`;

	url = addQueryParamToUrl(url, { name: 'page', value: page });
	url = addQueryParamToUrl(url, { name: 'limit', value: limit });
	url = addQueryParamToUrl(url, { name: 'search', value: search });
	url = addQueryParamToUrl(url, { name: 'orderBy', value: orderBy });
	url = addQueryParamToUrl(url, { name: 'orderDir', value: orderDir });
	url = addQueryParamToUrl(url, { name: 'username', value: username });
	if (requestType !== undefined) {
		url = addQueryParamToUrl(url, {
			name: 'wantsNationalFunds',
			value: requestType === PROJECT_TYPE.CASA_VERDE,
		});
	}
	if (filters && filters.length > 0) {
		filters.map((filter: IApiFilter, index: number) => {
			if (filter.selectedFilterId !== 'all' && filter.selectedFilterId !== -1) {
				url = addQueryParamToUrl(url, {
					name: filter.filterProperty,
					value: filter.selectedFilterId,
				});
				if (filter.meta !== undefined) {
					// Stringify and encode the 'meta' object
					const metaString = encodeURIComponent(JSON.stringify(filter.meta));
					url = addQueryParamToUrl(url, {
						name: 'meta',
						value: metaString,
					});
				}
			}
		});
	}

	const response = await fetch(url);
	const data: IPaginationData<T> = await response.json();

	return data;
};

interface IDownloadable {
	type: number;
}

export const apiDownload = async (url: string, options: IDownloadable) => {
	try {
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				Accept:
					options.type === SCREENSHOT_TYPE.IMAGE
						? 'image/jpeg'
						: 'application/pdf',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(options),
		});

		if (!response.ok) {
			logger.error(`Error ${response.status}: ${response.statusText}`);
		}

		const content = response;
		return content;
	} catch (error) {
		logger.error(`Error in apiPost: ${error}`);
		throw error;
	}
};
