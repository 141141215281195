import { INumberEnumeration } from 'data/abstractions/uiInterfaces';
import { ENTITY_LISTS_API_USER_TYPE } from './constants';

export const PROJECT_STATUS: INumberEnumeration = {
	REQUEST_PENDING: 0,
	REQUEST_ACTIVE: 1,
	OFFER_PENDING: 2,
	OFFER_ACTIVE: 3,
	REQUEST_RECEIVED_OFFER: 4,
	REQUEST_ACCEPTED_OFFER: 5,
	//PROJECT_CREATED: 6,
	PROJECT_UPLOAD_INSTALLER_CONTRACT: 6,
	PROJECT_UPLOAD_CUSTOMER_CONTRACT: 7,
	PROJECT_DOWNPAYMENT: 8,
	PROJECT_IMPLEMENTATION: 9,
	PROJECT_SETTLEMENT_PENDING: 10,
	PROJECT_SETTLEMENT_COMPLETED: 11,
	PROJECT_PROSUMER_CONTRACT_PENDING: 12,
	PROJECT_PROSUMER_CONTRACT_COMPLETED: 13,
	PROJECT_REVIEW: 14,
	PROJECT_FINALIZED: 15,
	PROJECT_CROSS_SELL: 16,
};

export const PROJECT_TYPE: INumberEnumeration = {
	STANDARD: 0,
	CASA_VERDE: 1,
};

export const REQUEST_STATE: INumberEnumeration = {
	NEW: 0,
	OFFERED: 1,
};

export const RESOURCE_DELETED_STATE: INumberEnumeration = {
	NO: 0,
	YES: 1,
};

export const getStatusName = (status: number, apiType?: string) => {
	if (
		apiType !== undefined &&
		apiType === ENTITY_LISTS_API_USER_TYPE.INSTALLER &&
		status === PROJECT_STATUS.REQUEST_RECEIVED_OFFER
	) {
		return 'REQUEST_SENT_OFFER';
	}
	const statusKey = Object.keys(PROJECT_STATUS).find(
		(key) => PROJECT_STATUS[key] === status
	);
	return statusKey;
};

export const getProjectStatusPercentage = (status: number) => {
	const percentage = ((status + 1) * 100) / Object.keys(PROJECT_STATUS).length;
	return percentage;
};

export const isProjectStatusInSettlementStage = (status: number) => {
	if (status === PROJECT_STATUS.PROJECT_UPLOAD_INSTALLER_CONTRACT) return true;
	if (status === PROJECT_STATUS.PROJECT_UPLOAD_CUSTOMER_CONTRACT) return true;
	if (status === PROJECT_STATUS.PROJECT_DOWNPAYMENT) return true;
	if (status === PROJECT_STATUS.PROJECT_IMPLEMENTATION) return true;
	if (status === PROJECT_STATUS.PROJECT_SETTLEMENT_PENDING) return true;
	if (status === PROJECT_STATUS.PROJECT_SETTLEMENT_COMPLETED) return true;
	if (status === PROJECT_STATUS.PROJECT_FINALIZED) return true;
	return false;
};

export const hasProjectReview = (status: number) => {
	if (status === PROJECT_STATUS.PROJECT_FINALIZED) return true;
	if (status === PROJECT_STATUS.PROJECT_CROSS_SELL) return true;
	return false;
};
