export enum SCREENSHOT_TYPE {
	IMAGE,
	PDF,
}

export enum SCREENSHOT_IMAGE_TYPE {
	JPEG = 'jpeg',
	PNG = 'png',
	WEBP = 'webp',
}
