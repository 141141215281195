export const CAROUSEL_OPTIONS = { loop: true, draggable: false };
export const CAROUSEL_IMGS = [
	{ path: '/images/landing/hero/hero-slide-1-min.webp', alt: 'alt 1' },
	{ path: '/images/landing/hero/hero-slide-2-min.webp', alt: 'alt 2' },
	{ path: '/images/landing/hero/hero-slide-4-min.webp', alt: 'alt 3' },
	// { path: "/images/landing/hero/hero-slide-4-min.webp", alt: "alt 4" },
];
export const CAROUSEL_BTNS_TEXTS = [
	{
		heading: {
			id: 'component.action.1.heading',
			defaultMessage: 'Power by synergy',
		},
		label: {
			id: 'component.action.1.label',
			defaultMessage: 'Get quote',
		},
		targetSection: '#estimator',
	},
	{
		heading: {
			id: 'component.action.2.heading',
			defaultMessage: 'Offer content',
		},
		label: {
			id: 'component.action.2.label',
			defaultMessage: 'Find out more',
		},
		targetSection: '#estimator',
	},
	{
		heading: {
			id: 'component.action.3.heading',
			defaultMessage: 'Become a partner',
		},
		label: {
			id: 'component.action.3.label',
			defaultMessage: 'Find out more',
		},
		targetSection: '#platform',
	},
	// {
	// 	heading: {
	// 		id: "component.action.4.heading",
	// 		defaultMessage: "Seller content",
	// 	},
	// 	label: {
	// 		id: "component.action.4.label",
	// 		defaultMessage: "Find out more",
	// 	},
	// 	targetSection: "#platform",
	// },
];
