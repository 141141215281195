import React, { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import platforms from '/styles/Platform.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import PhotonAnimation from 'components/animation/photonAnimation';
import CtaButton from 'components/ui/ctaButton';

export default function Platform() {
	const intl = useIntl();
	const containerRef = useRef(null);
	const [angle, setAngle] = useState(0);

	const updateAngle = () => {
		const containerElem = containerRef.current;
		let height = (containerElem! as HTMLElement).clientHeight;

		if ((containerElem! as HTMLElement)?.clientWidth < 992 - 17) {
			height /= 2;
		}

		const width = ((containerElem! as HTMLElement)?.clientWidth / 3) * 2;
		const angleRad = Math.atan(height / width);
		const angle = (angleRad * 180) / Math.PI;
		setAngle(angle);
	};
	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('resize', updateAngle, { passive: true });
			window.addEventListener('load', updateAngle, { passive: true });
			updateAngle();
		}
		return () => {
			window.removeEventListener('resize', updateAngle);
			window.removeEventListener('load', updateAngle);
		};
	}, []);

	return (
		<section className={`${platforms.platform}`} id='platform'>
			<div className={platforms.platformContainer} ref={containerRef}>
				<Image
					src='/images/landing/hero/hero-slide-3-min.jpg'
					alt='alt.real platform'
					className={platforms.platformImg}
					fill
				/>
				<div className={platforms.meshContainer}>
					<div className={platforms.mesh}></div>
				</div>
				<div className={platforms.photonContainer}>
					<PhotonAnimation icon={''} angle={angle}></PhotonAnimation>
				</div>
			</div>
			<div className={platforms.platformContent}>
				<div className={`${platforms.platformContentContainer} container`}>
					<h2 className='h1 fw-semibold'>
						<FormattedMessage
							id='component.platform.title'
							defaultMessage='Platform USP'
						/>
					</h2>
					<p className='col-9 col-lg-6'>
						<FormattedMessage
							id='component.platform.content'
							defaultMessage='default platform content'
						/>
					</p>
					<div className='mb-5 mt-3'>
						<CtaButton
							label={intl.formatMessage({
								id: 'component.nav.get_quote',
								defaultMessage: 'Get quote',
							})}
							href={'#estimator'}
							handler={(e) => {
								const targetSection = document.getElementById('estimator');
								if (targetSection !== null) {
									e.preventDefault();
									const offsetTop = targetSection?.offsetTop ?? 0;
									scroll({
										top: offsetTop,
										behavior: 'smooth',
									});
								}
							}}
						></CtaButton>
					</div>
				</div>
			</div>
		</section>
	);
}
