import { IProduct } from 'data/abstractions/configuratorInterfaces';
import { IntlShape } from 'react-intl';
import { ENV_VARS } from './constants';

export const isValidPositiveNumber = (value: number | undefined | null) => {
	if (value === undefined || value === null) return false;
	if (value < 0) return false;
	return true;
};
export const isValidNumber = (value: number | undefined | null) => {
	if (value === undefined || value === null) return false;
	if (value <= 0) return false;
	if (isNaN(value)) return false;
	return true;
};

export const isValidPercentage = (value: number | undefined | null) => {
	if (value === undefined || value === null) return false;
	if (value < 0) return false;
	if (value > 100) return false;
	return true;
};

export const isValidName = (value: string | undefined | null) => {
	if (value === undefined || value === null) return false;
	if (value.length === 0) return false;
	if (value.length > ENV_VARS.TEXTAREA_MAX_CHARS) return false;
	return true;
};

export const isValidUrl = (value: string | undefined | null) => {
	if (value === undefined || value === null) return false;
	if (value.length === 0) return false;
	const pattern = new RegExp(
		'^(https?:\\/\\/)?' + // protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
			'(\\#[-a-z\\d_]*)?$',
		'i'
	); // fragment locator
	return !!pattern.test(value);
};

export const isValidProductList = (
	equipmentList: IProduct[],
	validatePrices = true
) => {
	return equipmentList.every((equipment) => {
		const validName = isValidName(equipment.name);
		const validQty = isValidNumber(equipment.quantity);
		const validPrice = isValidNumber(equipment.pricePerUnit);
		const validVat = isValidPercentage(equipment.vat);

		let validProduct = validName && validQty;
		if (validatePrices) validProduct = validProduct && validPrice && validVat;
		return validProduct;
	});
};

export const validateEmail = (email: string, intl: IntlShape) => {
	const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;

	if (!emailRegex.test(email)) {
		return intl.formatMessage({
			id: 'component.validation.email.invalid',
			defaultMessage: 'The email is not valid',
		});
	}

	return null;
};

export const validatePhone = (phone: string, intl: IntlShape) => {
	const phoneRegex = /^(\+?\d{1,4}\s?)?(\d{10})$/;

	if (!phoneRegex.test(phone)) {
		return intl.formatMessage({
			id: 'component.validation.phone.invalid',
			defaultMessage: 'The phone is not valid',
		});
	}

	return null;
};

export const validateActivationCode = (code: string, intl: IntlShape) => {
	const codeRegex = /^\d{6}$/;

	if (!codeRegex.test(code)) {
		return intl.formatMessage({
			id: 'component.validation.code.invalid',
			defaultMessage: 'The code is not valid',
		});
	}

	return null;
};

export const isEmailValid = (email: string) => {
	const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
	return emailRegex.test(email);
};

export const isPhoneNumberValid = (phoneNumber: string) => {
	const phoneRegex = /^(\+?\d{1,4}\s?)?(\d{10})$/;
	return phoneRegex.test(phoneNumber);
};

export const isAlphaNumeric = (str: string) => {
	return /^[a-zA-Z0-9]+$/.test(str);
};
