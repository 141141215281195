import React from "react";
import components from "../../styles/components.module.scss";

interface IPhotonAnimation {
	icon?: string;
	isSlider?: boolean;
	selectedIndex?: number;
	selectIndex?: (index: number) => void;
	angle: number;
}

export default function CarouselSlider({
	icon,
	isSlider = false,
	selectedIndex,
	selectIndex,
	angle,
}: IPhotonAnimation) {
	const selected = (index: number) =>
		selectedIndex === index ? components.selected : "";
	return (
		<div className={`${components.carouselSliderContainer}`}>
			<div className={`${components.carouselSlider}`}>
				<div
					className={components.dotsContainer}
					style={
						{
							//transform: `translateX(calc(${selectedIndex} * (-34px - 1rem)))`,
						}
					}
				>
					{Array.from(Array(3).keys()).map((index) => {
						return (
							<span
								className={selected(index)}
								onClick={() => !!selectIndex && selectIndex(index)}
								key={index}
							></span>
						);
					})}
				</div>
				{!isSlider && <span>{icon}</span>}
				{/* <div
				className={components.photon}
				style={{ transform: `rotate(-${angle ?? 45}deg)` }}
			></div> */}
			</div>
		</div>
	);
}
